import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { getHomePath } from "../utils/path"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import Logo from "../images/logo.svg"
import { Image } from "@theme-ui/components"
import { ChevronDown, ChevronUp, Menu, X } from "react-feather"
import * as Scroll from "react-scroll"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
const LinkScroll = Scroll.Link

const MobileNav = ({ pageSections }) => {

  const primary = getColor(themeUiTheme, "primary")
  const secondary = getColor(themeUiTheme, "secondary")
  const yellow = getColor(themeUiTheme, "customYellow")

  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = pageSections

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box>
        <Container sx={{ backgroundColor: "customYellow", paddingY: [2, 3] }}>
          <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <InboundLink to={getHomePath(locale)}>
                <Image src={Logo} sx={{ width: "100px" }} />
              </InboundLink>
            </Box>
            <Box onClick={() => setShow(!show)}>
              <Menu color={primary} />
            </Box>
          </Flex>
        </Container>
      </Box>
      {show && (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "primary",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 1000,
            height: "auto",
            maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <Container sx={{ backgroundColor: "primary", paddingY: [2, 3] }}>
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <InboundLink to={getHomePath(locale)}>
                  <Image src={Logo} sx={{ width: "100px" }} />
                </InboundLink>
              </Box>
              <Box onClick={() => setShow(!show)}>
                <X color="#ffffff" />
              </Box>
            </Flex>
          </Container>
          <Flex
            sx={{
              flexDirection: "column",
              p: 2,
              margin: 0,
              alignItems: "start",
              listStyle: "none",
            }}
            as="ul"
          >
            {menu.map(item => (
              <TextComponent setShow={setShow} item={item} locale={locale} key={item.id} />
            ))}
          </Flex>
        </Box>
      )}
    </Box>
  )
}

const TextComponent = ({ item, locale, setShow }) => {
  const componentTheme = "light"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        minHeight: "55px",
        position: "relative",
        fontWeight: "normal",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a, & > div": {
          color: "light",
          paddingX: 3,
          paddingY: [3],
          display: "block",
        },
      }}
      onClick={() => setShow(false)}
    >
      <Flex
        sx={{
          display: "flex!important",
          cursor: "default",
          alignItems: "center",
        }}
      >
        <LinkScroll
          onClick={()=>setShow(false)}
          spy={false}
          smooth={true}
          duration={500}
          to={`${item.id}`}
        >
          {item.title}
        </LinkScroll>
      </Flex>
    </Box>
  )
}

export default MobileNav
