import { Box, Container, Flex, Grid, Text, Image } from "@theme-ui/components"
import React from "react"
import { useCompany } from "../hooks/useCompany"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import {
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Linkedin,
  MapPin,
} from "react-feather"
import Logo from "../images/logo.svg"
import { getColor } from "@theme-ui/color"
import { InboundLink, OutboundLink } from "../components/link"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import CommunityTelmotor from "../images/CommunityTelmotor.png"

const Footer = () => {
  const company = useCompany()
  const social = useSocial()

  const primary = getColor(themeUiTheme, "primary")
  const dark = getColor(themeUiTheme, "dark")

  const footer = []
  return (
    <Box
      as="footer"
      sx={{ backgroundColor: "customYellow", py: 5, color: "text" }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Container>
              <Grid
                columns={["1fr", "1fr", "1fr 1fr 1fr"]}
                sx={{ alignItems: "center" }}
              >
                <Box
                  sx={{ paddingX: 2, paddingY: 3, img: { height: "140px" } }}
                >
                  <Image variant="avatar" src={Logo} alt="Logo" />
                </Box>
                <Flex sx={{ alignItems: "start", mx: [0, 5] }}>
                  <Box>
                    <MapPin color={primary} size={32} />
                  </Box>
                  <Box
                    sx={{
                      px: [3],
                      fontWeight: "600",
                      "*:first-child": { my: [0] },
                    }}
                    dangerouslySetInnerHTML={{ __html: company.body }}
                  />
                </Flex>

                {footer.map(column => (
                  <List key={column.id}>
                    {column.treeChildren.map(link => (
                      <Item>
                        <Text
                          sx={{ fontWeight: "bold", mb: 2, display: "block" }}
                        >
                          {link.link ? (
                            <MagicLink item={link.link} />
                          ) : (
                            link.anchor
                          )}
                        </Text>
                        <List key={link.id}>
                          {link.treeChildren.map(link => (
                            <Item>
                              {link.link ? (
                                <MagicLink item={link.link} />
                              ) : (
                                link.anchor
                              )}
                            </Item>
                          ))}
                        </List>
                      </Item>
                    ))}
                  </List>
                ))}
                <Box
                  sx={{
                    paddingX: 2,
                    paddingY: 3,
                    img: { height: "40px", width:"auto", objectFit:"contain" },
                    textAlign: ["left","left","right"],
                  }}
                >
                  <Flex sx={{ flexDirection:"column"}}> 
                    <Box sx={{py:[2]}}>CON IL SOSTEGNO DI:</Box>
                    <Box>
                      <OutboundLink href="https://www.telmotor.it/community/" target="_blank">
                          <Image src={CommunityTelmotor} />
                      </OutboundLink>
                    </Box>
                  </Flex>
                </Box>
              </Grid>
            </Container>
            <Container
              sx={{ py: 0, borderTop: "1px solid", borderColor: "dark" }}
            >
              <Flex
                sx={{
                  display: "flex",
                  py: 1,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Grid columns={[4]} gap={[3, 4]} sx={{ mt: [2, 0] }}>
                    {social.facebook && (
                      <Box>
                        <MagicLink target="_blank" href={social.facebook}>
                          <Facebook color={dark} />
                        </MagicLink>
                      </Box>
                    )}
                    {social.twitter && (
                      <Box>
                        <MagicLink target="_blank" href={social.twitter}>
                          <Twitter color={dark} />
                        </MagicLink>
                      </Box>
                    )}
                    {social.instagram && (
                      <Box>
                        <MagicLink target="_blank" href={social.instagram}>
                          <Instagram color={dark} />
                        </MagicLink>
                      </Box>
                    )}
                    {social.linkedin && (
                      <Box>
                        <MagicLink target="_blank" href={social.linkedin}>
                          <Linkedin color={dark} />
                        </MagicLink>
                      </Box>
                    )}
                    {social.youtube && (
                      <Box>
                        <MagicLink target="_blank" href={social.youtube}>
                          <Youtube color={dark} />
                        </MagicLink>
                      </Box>
                    )}
                  </Grid>
                </Box>

                <Box
                  sx={{
                    span: { color: "primary" },
                    fontWeight: "400",
                    fontSize: [1],
                    pt: [3, 0],
                  }}
                >
                  Designed with love by{" "}
                  <OutboundLink
                    href="http://www.multi-consult.it"
                    target="_blank"
                  >
                    <span>© MULTI 2022</span>
                  </OutboundLink>
                  . All rights reserved.
                  <OutboundLink href="https://www.iubenda.com/privacy-policy/63021092" target="_blank">
                    {" "}
                    Privacy
                  </OutboundLink>{" "}
                  e{" "}
                  <OutboundLink href="https://www.iubenda.com/privacy-policy/63021092/cookie-policy" target="_blank">
                    Cookie Policy
                  </OutboundLink>
                </Box>
              </Flex>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 4,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 2, "&:last-child": { mb: 0 } }} />
}

export default Footer
