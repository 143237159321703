import { Box } from "@theme-ui/components"
import React from "react"
import Nav from "./nav"
import MobileNav from "./mobileNav"

const Header = ({ pageSections }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: "9999",
      }}
    >
      <Box sx={{ display: ["none", "none", "none", "block"] }}>
        <Nav pageSections={pageSections} />
      </Box>
      <Box sx={{ display: ["block", "block", "block", "none"] }}>
        <MobileNav pageSections={pageSections} />
      </Box>
    </Box>
  )
}

export default Header
