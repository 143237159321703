import { Box, Container, Flex, Image } from "@theme-ui/components"
import React, { useState } from "react"
import { getHomePath, getSearchPath } from "../utils/path"
import { InboundLink } from "./link"
import { MagicLink } from "../utils/magicLink"
import LanguageSwitcher from "./languageSwitcher"
import { debounce } from "lodash"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import * as Scroll from "react-scroll"
import Logo from "../images/logo.svg"
const LinkScroll = Scroll.Link

const Nav = ({ pageSections }) => {
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = []

  return (
    <Box as="nav">
      <Container variant="header" sx={{ py: [3] }}>
        <Flex sx={{ flexDirection: "row", justifyContent: "space-between", alignItems:"center" }}>
          <Box sx={{ paddingX: 2, paddingY: 3 , img:{ height: "140px"}}}>
            <Image variant="avatar" src={Logo} alt="Logo" />
          </Box>
          <Flex
            sx={{
              flexDirection: "row",
              padding: 0,
              margin: 0,
              listStyle: "none",
            }}
            as="ul"
          >
            {/* {pageSections.map(item => (
              <TextComponent item={item} locale={locale} key={item.id} />
            ))} */}

            {pageSections.map(item => (
              <Box
                as="li"
                sx={{
                  position: "relative",
                  marginX: 1,
                  paddingX: 2,
                  paddingY: 3,
                }}
              >
                {console.log("item", item.to)}
                <LinkScroll
                  spy={false}
                  smooth={true}
                  duration={500}
                  to={`${item.id}`}
                  href="#"
                >
                  {item.title}
                </LinkScroll>
              </Box>
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

const TextComponent = ({ item, locale, index }) => {
  const [show, setShow] = useState(false)

  const debouncedHandleMouseEnterMenu = debounce(() => {
    setShow(true)
  }, 200)

  const handlOnMouseLeaveMenu = () => {
    setShow(false)
    debouncedHandleMouseEnterMenu.cancel()
  }

  return (
    <Box
      as="li"
      key={item.id}
      sx={{ position: "relative", marginX: 1, paddingX: 2, paddingY: 3 }}
      onMouseEnter={() => debouncedHandleMouseEnterMenu()}
      onMouseLeave={() => handlOnMouseLeaveMenu()}
    >
      {item.link ? (
        <MagicLink item={item.link} locale={locale}></MagicLink>
      ) : (
        <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
      )}
      {item.treeChildren.length > 0 && show && (
        <Box
          as="ul"
          sx={{
            listStyle: "none",
            padding: 3,
            margin: 0,
            backgroundColor: "lightBackground",
            position: "absolute",
            top: 40,
            width: "max-content",
            boxShadow:
              "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
          }}
        >
          {item.treeChildren.map(item =>
            item.anchor ? (
              <Box as="li" key={item.id}>
                {item.link ? (
                  <MagicLink item={item.link} locale={locale} />
                ) : (
                  <Box sx={{ cursor: "default" }}>{item.anchor}</Box>
                )}
              </Box>
            ) : null
          )}
        </Box>
      )}
    </Box>
  )
}

export default Nav
